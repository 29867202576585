import { INITIAL_PERMISSION_COL_DEF } from "presentation/constant/UserGroup/PermissionColumnDefinition";
import { PermissionConstant } from "presentation/constant/UserGroup/PermissionConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { PermissionEntity } from "presentation/model/UserGroup/PermissionEntity";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";

import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useMemo, useState } from "react";

const PermissionTablePanel: React.FC = () => {
    const [userGroupState] = useUserGroupTracked();
    const { isEditable, isRead } = userGroupState.permissionState;
    const { isEditable: isUserEditable, isAdd: isUserAdd, isTabularDataActive: isUserTabularDataActive } = userGroupState.userState;
    const { isEditable: isPermissionEditable, isAdd: isPermissionAdd } = userGroupState.permissionState;
    const userGroupVM = useUserGroupVM();
    const currentGroup = userGroupState.userGroupState.currentSelectedData;
    const [tableRowSelect, setTableRowSelect] = useState<any>({});
    const tableId = 'permission-table';
    const PERMISSION_CONSTANT = PermissionConstant.permission;

    const handleAddClick = useCallback(() => {
        userGroupVM.onPermissionAddClick();
    }, [userGroupVM]);

    //not need at the mement
    // const handleRowDoubleClick = useCallback((permission: PermissionEntity) => {
    //     userGroupVM.onPermissionRowDoubleClick(permission);
    // }, [userGroupVM]);

    const handleDelete = useCallback((permission: PermissionEntity) => {
        userGroupVM.onPermissionRowDeleteClick(permission);
    }, [userGroupVM]);

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={tableId}
                columns={INITIAL_PERMISSION_COL_DEF}
                headerLabel={PERMISSION_CONSTANT.PERMISSION}
                onAddClick={handleAddClick}
                // onRowDoubleClick={(e: any, permission: PermissionEntity) => handleRowDoubleClick(permission)}
                data={currentGroup.permissions || []}
                showPaginator={false}
                editable={false}
                onRowClick={(e: any, i: any) => {
                    setTableRowSelect((prevState: any) => ({
                        ...prevState,
                        [tableId]: isUserAdd || isUserEditable ? { ...prevState[tableId] } : { [i]: true },
                    }));
                }}
                isRowHighligted={tableRowSelect[tableId] ? true : false}
                isRowChange={!(isUserAdd || isUserEditable)}
                showAddIcon={!(isEditable && isRead) && !isUserAdd && !isUserEditable && !isPermissionEditable && !isPermissionAdd}
                showUploadIcon={!(isEditable && isRead) && !isUserAdd && !isUserEditable && !isPermissionEditable && !isPermissionAdd}
                showDeleteButton={!(isEditable && isRead) && !isUserAdd && !isUserEditable && !isPermissionEditable && !isPermissionAdd}
                showActionColumn={true}
                showReloadIcon={false}
                selectionMode={false}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                onDelete={handleDelete}
                gridHeight="customHeight"
                customHeight="calc(100vh - 72px)" />
        );
    }, [currentGroup.permissions, handleAddClick, handleDelete, isEditable, isRead,
        isUserEditable, isUserAdd, tableRowSelect, PERMISSION_CONSTANT.PERMISSION, isPermissionEditable, isPermissionAdd])

    return <TableWrapper isHide={!isUserTabularDataActive}>{memoGroupTable}</TableWrapper>
}

export default PermissionTablePanel;