import { UserGroupConstant, userGroupRequiredFieldList } from "presentation/constant/UserGroup/UserGroupConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo } from "react";
import { FieldType } from "veronica-ui-component/dist/component/core";

const UserGroupForm = () => {
    const [ userGroupState ] = useUserGroupTracked()
    const userGroupVM = useUserGroupVM()
    const {isRead, isSaveClicked, isAdd } = userGroupState.userGroupState;
    const currentUserGroup = userGroupState.userGroupState.currentSelectedData;
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;
    const allFormState = userGroupState.userGroupState.allFormState;

    return (<>
        <div className={'im-flex-row'}>
            <div className={'flex-row-item'} style={{marginBottom:"10px"}}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={!isAdd}
                    readOnlyValue={currentUserGroup.name || ''}
                    fieldValue={currentUserGroup.name}
                    fieldLabel={USERGROUP_CONSTANT.USERGROUPNAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'name'}
                    maxLength={30}
                    requiredFieldList={userGroupRequiredFieldList}
                    onFieldChange={userGroupVM.onUserGroupFieldChange} />
            </div>
        </div>
        <div className={'im-flex-row'}>
            <div className={'flex-row-item'}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    readOnlyValue={currentUserGroup.description || ''}
                    fieldValue={currentUserGroup.description}
                    fieldLabel={USERGROUP_CONSTANT.USERGROUPDESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'description'}
                    maxLength={60}
                    requiredFieldList={userGroupRequiredFieldList}
                    onFieldChange={userGroupVM.onUserGroupFieldChange} />
            </div>
        </div>
    </>);
}

export default memo(UserGroupForm);