import { UserConStant, userRequiredFieldList } from "presentation/constant/User/UserConstant";
import { } from "presentation/hook/User/useUserVM";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo } from "react";
import { FieldType } from "veronica-ui-component/dist/component/core";

const UserForm = () => {
    const [userGroupState] = useUserGroupTracked();
    const userGroupVM = useUserGroupVM();
    const { isRead, isSaveClicked } = userGroupState.userState;
    const currentUser = userGroupState.userState.currentSelectedData;
    const USER_CONSTANT = UserConStant.user;
    const allFormState = userGroupState.userState.allFormState;

    return (<>
        <div className={'im-flex-row'}>
            <div className={'im-flex-row-item'} style={{ width: '390px' }}>

                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    readOnlyValue={currentUser.nameList?.join(",") || ''}
                    fieldValue={currentUser.nameList}
                    fieldLabel={USER_CONSTANT.USERNAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'nameList'}
                    isMultipleDropDown={true}
                    requiredFieldList={userRequiredFieldList}
                    options={userGroupState.dynamicOptions.userDropdownOptions ?? []}
                    onFieldChange={userGroupVM.onUserFieldChange} />
            </div>
        </div>
        {/* <div className={'im-flex-row'}>
            <div className={'flex-row-item'}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    readOnlyValue={currentUser.description || ''}
                    fieldValue={currentUser.description}
                    fieldLabel={USER_CONSTANT.DESCRIPTION}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'description'}
                    maxLength={60}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={userGroupVM.onUserFieldChange} />
            </div>
        </div> */}
    </>);
}

export default memo(UserForm);